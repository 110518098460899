.side-panel-container {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.side-panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.side-panel-header {
    flex-shrink: 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.date-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 76%;
    margin: auto;
    border-bottom-color: rgba(112, 112, 112, 1);
    border-bottom-style: none;
    border-bottom-width: 1px;
    border-left-color: rgba(112, 112, 112, 1);
    border-left-style: none;
    border-left-width: 1px;
    border-right-color: rgba(112, 112, 112, 1);
    border-right-style: none;
    border-right-width: 1px;
    border-top-color: rgba(112, 112, 112, 1);
    border-top-style: none;
    border-top-width: 1px;
    box-shadow: none;
    border-radius: 10px;
    background-color: #d9d6cf;
    padding: 10px 0;
}

.date-font {
    text-align: center;
    margin: auto;
    font-size: 180%;
    font-style: normal;
    font-family: 'Adobe Clean', Arial;
    text-decoration: none;
    overflow-x: unset;
    overflow-y: unset;
    color: #413f3d;
    font-weight: bold;
}

.carousel-container {
    margin-top: 10px;
    width: 100%;

}

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-image {
    max-height: 300px;
    width: 85%;
    object-fit: contain;
    cursor: pointer;
}

.carousel-arrow {
    position: relative;
}

.page-header {
    margin: 10px !important;
}

.side-panel-content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    width: 85%;
    align-self: center;
}

.field-container {
    margin-bottom: 10px;
}

.side-panel-footer {
    flex-shrink: 0;
    padding: 10px;
    background-color: #f5f5f5;
    border-top: 1px solid rgba(112, 112, 112, 0.3);
    display: flex;
    flex-direction: column;
}

.navigation-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.byline {
    margin-top: 10px;
    text-align: center;
}

.side-panel-footer-button {
    align-self: flex-end;
}

.side-panel-footer-edit-add {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.dialog-image {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}
