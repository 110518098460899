@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');


.navigation-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.page-count-indicator {
    margin: 0 10px;
    font-family: 'Adobe Clean', Arial;
}

.timeline-slider {
    width: 63vw;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    position: relative;
}

.timeline-slider::-webkit-slider-runnable-track {
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
}

.timeline-slider::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
}

.timeline-slider::-moz-range-track {
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
}

.timeline-slider::-moz-range-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
}

.timeline-slider::-ms-track {
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
}

.timeline-slider::-ms-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #000;
    cursor: pointer;
}

.timeline-slider::-webkit-slider-runnable-track:hover {
    background-color: #bbb;
}

.timeline-slider::-webkit-slider-thumb:hover {
    background-color: #aaa;
}

.slider-container {
    position: relative;
    width: 62vw;
    margin: 2px auto;
}

.year-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 90%;
    height: 4px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    /* add some padding to make room for the labels */
    padding: 2vh 4vw;
}

.year-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 90%;
    bottom: 0;
}

.slider-labels span {
    position: relative;
    display: flex;
    justify-self: center;
    justify-content: center;
    text-align: center;
    width: 1em;
    bottom: 2em;
}

.slider-dots {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    bottom: 10px;
}

.slider-dots::before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: #000;
    border-radius: 50%;
}
.popup-header {
    font-family: 'Courier New', monospace;
    font-size: 18px;
    font-weight: bold;
}

.popup-item-key {
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.popup-item {
    font-family: sans-serif;
    font-size: 14px;
}

.year-display {
    font-family: 'Open Sans', sans-serif;
    font-size: medium;

}
.page-header {
    font-family: "Bodoni 72 Smallcaps" !important;
    font-size: x-large !important;
    font-weight: bold !important;
    letter-spacing: 1px !important;
}

.page-item-key {
    font-family: sans-serif !important;
    font-size: 14px !important;
    font-weight: bold !important;
    text-align: left !important;

}

.page-item {
    display: flex !important;
    font-family: sans-serif !important;
    font-size: 14px !important;
    list-style-type: none !important;
    text-align: left !important;
    padding-top: 1.5vh !important;
    padding-right: 1vw !important;
    padding-left: 0 !important;
    padding-bottom: 1.5vh !important;
    overflow-wrap: break-word !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    flex-wrap: wrap !important;
    overflow-wrap: anywhere !important;
}
.page-year {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    font-size: xx-large;
    padding: 50px 50px 50px 50px;

}
.page-list {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.page-list-container {
    height: 65vh;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
}

.form-title {
    text-align: left;
    padding-left: 2vw;
    padding-right: 1vw;
    padding-bottom: 1vh;
    padding-top: 1vh;

}
.form-title2 {
    text-align: left;
    padding-left: 2vw;
    padding-right: 1vw;
    padding-bottom: 1vh;
    padding-top: 1vh;

}
.form-input {
    width: 66%;


}
.form-input2 {


}
.form-container {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: space-around;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-wrap: wrap;
}
.form-container1 {
    width: 100%;
    height: 90%;
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-wrap: nowrap;
    flex-direction: column;
    flex-grow: 1;
}
.form-container2 {
    width: 100%;
    height: 90%;
    position: relative;
    top: 1%;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;

}

.label-flex {
    display: flex;
    justify-content: left;
    align-items: center;
}
.form-titleyear {
    text-align: left;
    padding-left: 2vw;
    padding-right: 1vw;
    padding-bottom: .5vh;
    padding-top: 0;
}
.form-error {
    font-family: Arial;
    font-weight: bold;
    color: crimson;

}
.form-text {
    display: flex;
    position: relative;
    padding-top: 2.5vh;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    width: 66%;


}
.form-year {
    display: flex;
    width: 66%;

}
.cont-back-button {
    position: absolute;
    top: 10px;
    left: 10px;
}
.cont-close-button {
    position: absolute;
    top: 10px;
    left: 10px;
}

.test{

    color: firebrick;

}
.leaflet-container.selecting {
    cursor: crosshair !important;
}
.opacitysliderlabel{
    background-color: lightgrey;
    border-radius: 8px;
}
.map-container {
    width: 100vw;
    height: 100vh;
    position: relative;
}
.with-side-panel {
    width: 66vw;
}
.sidepanelclose-div {
    position: absolute;
    width: 100%;
    height: 5vh;
    background: #f7f6f5;
    top: 0;
    right: 0;
}
.sidepanelclose {

}
.sidepanel {
    position: absolute;
    display: flex;
    right: 0px;
    top: 5vh;
    width: 100%;
    min-width: 300px;
    height: 95%;
    overflow: hidden;
    background: #f7f6f5;
    z-index: 1000;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}
.contributemapbutton {
    position: relative;
    top: 95%;
    right: -40%;
    z-index: 100000;
    justify-content: right;
}
.consent {
    font-size: .7rem !important;
    width: 50%;
}