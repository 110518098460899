
      body {
        font-family: 'Open Sans', serif;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 33%;
        -webkit-font-smoothing: antialiased;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
      }

      

      .web19201 {

      display: flex;
      position: relative;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      overflow-x: auto;
      overflow-y: auto;
    width: 100%;
    height: 12%;
    justify-content: center;
    align-items: center;

}

.web19201 .rectangle2 {
    position: relative;
    display: flex;
    padding-top: 10.00px;
      padding-right: 0.00px;
      padding-bottom: 10.00px;
      padding-left: 0.00px;
      background-color: #d9d6cf;
      border-bottom-color: rgba(112, 112, 112, 1);
      border-bottom-style: none;
      border-bottom-width: 1.00px;
      border-left-color: rgba(112, 112, 112, 1);
      border-left-style: none;
      border-left-width: 1.00px;
      border-right-color: rgba(112, 112, 112, 1);
      border-right-style: none;
      border-right-width: 1.00px;
      border-top-color: rgba(112, 112, 112, 1);
      border-top-style: none;
      border-top-width: 1.00px;
      border-top-left-radius: 10.00px;
      border-top-right-radius: 10.00px;
      border-bottom-left-radius: 10.00px;
      border-bottom-right-radius: 10.00px;
      box-shadow: none;
    width: 76%;
    height: 95%;
    margin: auto;
    justify-content: center;
    align-items: center;
    z-index: 9;

}

.web19201 .xYear {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    /* include these if the div does not already have a defined height and width */
    font-size: 180%;
    font-style: normal;
    font-family: 'Adobe Clean', Arial;
    text-decoration: none;
    box-shadow: none;
    overflow-x: unset;
    overflow-y: unset;
    color: #413f3d;
    font-weight: bold;
    z-index: 1000;
}

.web19201 .x1920 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      letter-spacing: 0.00px;
      color: rgba(69, 69, 69, 1);
      -webkit-text-stroke: 2.00px rgba(0, 0, 0, 0);
      font-size: 275%;
      font-weight: 800;
    font-style: normal;
      font-family: 'Adobe Clean', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
    top: 2.5%;
    left: 20%;

}

.web19201 .iconMaterialNavigateNext {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(69, 69, 69, 1);
      box-shadow: none;
    width: 9%;
    height: 50%;
    right: 2%;

}

.web19201 .iconMaterialNavigateBefore {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      fill: rgba(69, 69, 69, 1);
      box-shadow: none;

    width: 9%;
    height: 50%;
    left: 2%;

}

.web19201 .ponikFred {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: center;
      line-height: 40.00px;
      letter-spacing: 0.00px;
      color: rgba(112, 112, 112, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 400;
    font-style: normal;
      font-family: 'Helvetica Neue', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      white-space: pre;
      width: 634.00px;
    height: 23.00px;
    left: 0.00px;
    top: 245.50px;

}

.web19201 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645 {
    position: absolute;
      box-sizing: content-box;
      -ms-transform: rotate(0.00deg);
    -webkit-transform: rotate(0.00deg);
    transform: rotate(0.00deg);
    -ms-transform-origin: center;
    -webkit-transform-origin: center;
    transform-origin: center;
      opacity: 1;
      cursor: default;
      margin-top: 0.00px;
      margin-right: 0.00px;
      margin-bottom: 0.00px;
      margin-left: 0.00px;
      padding-top: 0.00px;
      padding-right: 0.00px;
      padding-bottom: 0.00px;
      padding-left: 0.00px;
      background-color: rgba(255, 255, 255, 0);
      text-align: left;
      line-height: 34.00px;
      letter-spacing: 0.00px;
      color: rgba(112, 112, 112, 1);
      -webkit-text-stroke: 1.00px rgba(0, 0, 0, 0);
      font-size: 20.00px;
      font-weight: 400;
    font-style: normal;
      font-family: 'Helvetica Neue', Arial;
      text-decoration: none;
      box-shadow: none;
      overflow-x: unset;
      overflow-y: unset;
      width: 590.00px;
    height: 507.00px;
    left: 44.00px;
    top: 329.50px;

}

.web19201 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645-0 {
    color: rgba(112, 112, 112, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 20.00px;
    font-family: 'Helvetica Neue', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.web19201 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645-165 {
    color: rgba(112, 112, 112, 1);
    font-weight: 700;
    font-style: normal;
    font-size: 20.00px;
    font-family: 'Helvetica Neue', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

.web19201 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645 .address2628North30thStreetMilwaukeeWi53210UnitedStatesOfAmericaoccupantPonikFredownerhistoricalElementhistoricalArtifactsourcesnotesmgrs16tdn2256868645-170 {
    color: rgba(112, 112, 112, 1);
    font-weight: 400;
    font-style: normal;
    font-size: 20.00px;
    font-family: 'Helvetica Neue', Arial;
    letter-spacing: 0;
    text-decoration: none;
}

